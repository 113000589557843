import { setDateTime } from "../actions/searchForm";
import { useSearchForm } from "../hooks/useSearchForm";

type HandleDateTimeChangeReturnType = {
	handleDateChange: ({ changedDate }: { changedDate: Date }) => void;
	handleTimeChange: ({ name, value }: { name: string; value: string }) => void;
};

export const HandleDateTimeChange = (
	direction: "oneway" | "return",
	dateTime: Date,
): HandleDateTimeChangeReturnType => {
	const { dispatch } = useSearchForm();

	const handleDateChange = ({ changedDate }: { changedDate: Date }): void => {
		const hours = dateTime.getHours();
		const minutes = dateTime.getMinutes();

		changedDate.setHours(hours, minutes);

		dispatch(setDateTime(direction, changedDate));
	};

	const handleTimeChange = ({ name, value }: { name: string; value: string }): void => {
		if (name === `hours-${direction}`) {
			dateTime.setHours(Number(value));
		}

		if (name === `minutes-${direction}`) {
			dateTime.setMinutes(Number(value));
		}

		dispatch(setDateTime(direction, dateTime));
	};

	return { handleDateChange, handleTimeChange };
};
