import React, { useEffect, useRef } from "react";
import { Icon, Text, ListItem } from "@bookingcom/bui-react";
import HotelIcon from "@bookingcom/bui-assets-react/streamline/HotelIcon";
import GeoPinIcon from "@bookingcom/bui-assets-react/streamline/GeoPinIcon";
import TransportTrainIcon from "@bookingcom/bui-assets-react/streamline/TransportTrainIcon";
import TransportFerryIcon from "@bookingcom/bui-assets-react/streamline/TransportFerryIcon";
import PlaneTripIcon from "@bookingcom/bui-assets-react/streamline/PlaneTripIcon";
import { AutocompleteResultInterface } from "../../server/services/taxi-autocomplete";
import styles from "./TaxiAutocompleteSearchDropDown.module.css";
import { TEST_IDS } from "./TaxiAutocompleteSearchDropDown.constants";

interface LocationResultItemProps {
	id: string;
	index: number;
	name: string;
	onSelect: (result: AutocompleteResultInterface) => void;
	result: AutocompleteResultInterface;
	shouldFocus: boolean;
}

export const renderIcon = (type: string): JSX.Element => {
	switch (type) {
		case "airport": {
			return <PlaneTripIcon />;
		}

		case "hotel": {
			return <HotelIcon />;
		}

		case "ferry": {
			return <TransportFerryIcon />;
		}

		case "train_station": {
			return <TransportTrainIcon />;
		}

		case "underground_railway_station": {
			return <TransportTrainIcon />;
		}

		default: {
			return <GeoPinIcon />;
		}
	}
};

export const TaxiAutocompleteSearchDropDownItem: React.FunctionComponent<LocationResultItemProps> = ({
	result,
	index,
	shouldFocus,
	id,
	name,
	onSelect,
}: LocationResultItemProps): JSX.Element => {
	const buttonRef = useRef<HTMLLIElement>(null);

	const focusInput = (obj: React.RefObject<HTMLLIElement>): void => {
		if (obj?.current) {
			obj.current.focus();
		}
	};

	const handleClick = (): void => {
		onSelect(result);
	};

	useEffect(() => {
		if (shouldFocus) {
			focusInput(buttonRef);
		}
	}, [shouldFocus]);

	const listAttribute = shouldFocus
		? {
				"aria-selected": shouldFocus,
			}
		: {};

	return (
		<ListItem
			key={`${id}__listbox-item--${result.name}`}
			className={styles["taxi-autocomplete-search-drop-down-item"]}
			active={shouldFocus || undefined}
			tab-index={-1}
			onClick={handleClick}
			icon={<Icon svg={renderIcon(result.type)} />}
			attributes={{
				...listAttribute,
				"tabIndex": -1,
				"data-testid": `${TEST_IDS.autocompleteItemPrefix}-${name}-${index}`,
				"ref": buttonRef,
			}}
			spacing="small"
		>
			<Text variant="body_2">{result.name}</Text>
			<Text variant="small_1">
				{result.city}, {result.country}
			</Text>
		</ListItem>
	);
};
