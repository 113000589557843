import React, { useEffect, useState } from "react";
import { SkeletonCarCard } from "@btransport/taxi-car-card-component";
import { SheetContainer, Divider, Button, Stack, Text, Icon } from "@bookingcom/bui-react";
import { ArrowLeftIcon } from "@bookingcom/bui-assets-react/streamline";
import { getOrchestrator } from "@bookingcom/web-page-orchestrator";
import { formatDate } from "@bookingcom/date-format/format-date";
import shortDateWithWeekday from "@bookingcom/date-format/date-format-forms/short_date_with_weekday_time";
import { useTranslations } from "@bookingcom/lingojs-react";
import { RouteSummary } from "@btransport/route-summary-component/dist/cjs/implementations/RouteSummary";
import { useSearchForm } from "../../hooks/useSearchForm";
import { setReturnBannerClicked, setReturnJourney, submitForm } from "../../actions/searchForm";
import { AddReturnLeg, EMPTY_LEG } from "./interface";
import { TaxiDateTimePickerFormElement } from "../TaxiDateTimePickerFormElement";
import { REDIRECT_URL } from "../../constants/defaults";
import { formatCurrency } from "../../utilities/format-currency";
import { addMinutes } from "../../utilities/add-minutes";
import { AddReturnError } from "../AddReturnError";
import { AddReturnCarCard } from "../AddReturnCarCard";
import styles from "./AddReturnSheet.module.css";

export interface AddReturnSheetProps {
	dateRangeEnd?: Date;
	dateRangeStart?: Date;
	lang: string;
}

export const AddReturnSheet: React.FC<AddReturnSheetProps> = ({ dateRangeEnd, dateRangeStart, lang }) => {
	const { translate: t, i18n } = useTranslations();
	const { state, dispatch } = useSearchForm();
	const orchestrator = getOrchestrator();

	const [isVisible, setIsVisible] = useState(false);
	const [renderErrorModal, setRenderErrorModal] = useState(false);
	const [isReturnAdded, setIsReturnAdded] = useState(false);
	const [isLoadingReturn, setIsLoadingReturn] = useState(false);
	const [showSkeleton, setShownSkeleton] = useState<boolean>(true);
	const [outboundLeg, setOutboundLeg] = useState<AddReturnLeg>(EMPTY_LEG);
	const [returnResult, setReturnResult] = useState(null);
	const [carTypeResultReference, setCarTypeResultReference] = useState(null);
	const [totalPrice, setTotalPrice] = useState(0);

	const isMobile = state.screenSize.isSmall;

	const checkPriceClicked = async (e: React.MouseEvent): Promise<void> => {
		e.preventDefault();

		setIsLoadingReturn(true);
		setShownSkeleton(true);

		orchestrator?.runCommand("TRACK_GOAL", {
			experiment: "returnsV3",
			goal: "clicksOnCheckPrices",
		});

		const returnSearchRateResult = await orchestrator?.runQuery("RETURN_RATE");
		const getSelectedVehicle = await orchestrator?.runQuery("SELECTED_VEHICLE");

		const hasReturnSearchError = returnSearchRateResult?.errorList;
		const isValidReturnSearch = returnSearchRateResult?.journeys && getSelectedVehicle;

		if (hasReturnSearchError) {
			setRenderErrorModal(true);
			setIsReturnAdded(false);
			setIsLoadingReturn(false);
			setShownSkeleton(false);
			setReturnResult(false);
			setCarTypeResultReference(false);

			orchestrator?.runCommand("TRACK_STAGE", {
				experiment: "returnsV3",
				stage: "emptyReturnResult",
			});
		}

		if (isValidReturnSearch) {
			const returnSearchOutboundLeg = returnSearchRateResult.journeys[0].legs[0];
			const returnSearchReturnLeg = returnSearchRateResult.journeys[1].legs[0];

			const returnSearchReturnLegData = returnSearchReturnLeg.results.find(
				(item) => item.resultReference === getSelectedVehicle.carTypeResultReference?.toString(),
			);
			const returnSearchOutboundLegData = returnSearchOutboundLeg.results.find(
				(item) => item.resultReference === getSelectedVehicle.carTypeResultReference?.toString(),
			);

			setReturnResult(
				returnSearchReturnLeg.results.find(
					(item) => item.resultReference === getSelectedVehicle.carTypeResultReference?.toString(),
				),
			);

			setCarTypeResultReference(getSelectedVehicle.carTypeResultReference);
			setShownSkeleton(false);
			setIsReturnAdded(true);
			setIsLoadingReturn(false);

			const returnPickupTime = new Date(returnSearchReturnLeg.requestedPickupDateTime);
			const returnDropOffTime = addMinutes(
				new Date(returnSearchReturnLeg.requestedPickupDateTime),
				returnSearchReturnLegData.duration,
			);

			setOutboundLeg({
				pickUp: {
					title: returnSearchReturnLeg.dropoffLocation.name,
					date: returnDropOffTime
						? formatDate(i18n, { date: returnDropOffTime, form: shortDateWithWeekday })
						: undefined,
				},
				dropOff: {
					journeyTime: t("gt_mig_rides_route-summary_estimated-time", {
						variables: { minutes: returnSearchReturnLegData.duration },
					}),
					date: returnPickupTime ? formatDate(i18n, { date: returnPickupTime, form: shortDateWithWeekday }) : undefined,
					title: returnSearchReturnLeg.pickupLocation.name,
				},
			});

			setTotalPrice(returnSearchReturnLegData.price + returnSearchOutboundLegData.price);
		}
	};

	const sheetContainerOpened = async (): Promise<void> => {
		setRenderErrorModal(false);
		const rateResult = await orchestrator?.runQuery("RATE_RESULT");

		if (rateResult) {
			setOutboundLeg({
				pickUp: {
					title: rateResult.outbound.pickupLocation.name,
				},
				dropOff: {
					journeyTime: t("gt_mig_rides_route-summary_estimated-time", {
						variables: { minutes: rateResult.outbound.duration },
					}),
					title: rateResult.outbound.dropoffLocation.name,
				},
			});

			setShownSkeleton(false);
		}
	};

	const sheetContainerClosed = async (userHasCancelled = true): Promise<void> => {
		if (userHasCancelled) {
			dispatch(setReturnJourney(false));
		}

		setIsVisible(false);
		setIsReturnAdded(false);
		setIsLoadingReturn(false);
		setShownSkeleton(false);
		setReturnResult(false);
		setCarTypeResultReference(false);
		setRenderErrorModal(false);
	};

	const handleSubmit = (e: React.MouseEvent): void => {
		e.preventDefault();

		orchestrator?.runCommand("TRACK_GOAL", {
			experiment: "returnsV3",
			goal: "clicksOnAddReturnJourney",
		});

		sheetContainerClosed(false);

		dispatch(submitForm(REDIRECT_URL));
	};

	useEffect(() => {
		orchestrator?.on("TRIGGER_SEARCH_FORM_RETURN", async (): Promise<void> => {
			dispatch(setReturnJourney(true));
			dispatch(setReturnBannerClicked(true));
			setIsVisible(true);
		});

		orchestrator?.on("RATE_RESULT_UPDATED", async (): Promise<void> => {
			await sheetContainerOpened();
		});
	}, [orchestrator]);

	const isReturnResultComplete = returnResult && isReturnAdded && carTypeResultReference;
	const shouldShowLoadingSkeleton = isLoadingReturn && !isReturnAdded;
	const shouldShowAddReturnButton = isReturnAdded || isLoadingReturn;

	return (
		<SheetContainer
			position={isMobile ? "bottom" : "center"}
			closeAriaLabel={t("a11y_rides_web_add_a_return_modal_close_cta")}
			active={isVisible}
			title={
				<Stack direction="row" gap={4}>
					{isReturnAdded || renderErrorModal ? (
						<Stack.Item>
							<Button
								className={styles["back-button"]}
								variant="white"
								onClick={() => {
									setIsReturnAdded(false);
									setRenderErrorModal(false);
								}}
								attributes={{
									"aria-label": t("rides_web_add_a_return_date_time_label"),
									"data-testid": "return_modal_go_back_to_date_picker_button",
								}}
							>
								<Icon svg={ArrowLeftIcon} />
							</Button>
						</Stack.Item>
					) : undefined}
					<Stack.Item>{t("rides_web_add_a_return_modal_title")}</Stack.Item>
				</Stack>
			}
			size={isMobile ? undefined : 480}
			onCloseTrigger={sheetContainerClosed}
		>
			<Divider mixin={{ marginBlockStart: 2, marginInlineStart: -4, marginInlineEnd: -4 }} />

			{renderErrorModal ? (
				<AddReturnError onCloseEvent={sheetContainerClosed} />
			) : (
				<Stack direction="column" grow>
					<Stack.Item
						grow
						mixin={{
							paddingBlockEnd: 4,
							paddingBlockStart: 4,
						}}
					>
						<Text variant="strong_1">{t("rides_web_add_a_return_modal_subtitle")}</Text>
					</Stack.Item>
					<Stack.Item
						grow
						mixin={{
							paddingBlockEnd: 4,
						}}
					>
						<RouteSummary
							skeletonLoad={showSkeleton}
							pickUp={{ ...outboundLeg.dropOff }}
							dropOff={{ ...outboundLeg.pickUp }}
							flightAttached={false}
						/>
					</Stack.Item>
					{isLoadingReturn ||
						(!isReturnAdded && (
							<>
								<Stack.Item
									grow
									mixin={{
										paddingBlockEnd: 4,
									}}
								>
									<Text variant="strong_1">{t("rides_web_add_a_return_date_time_label")}</Text>
								</Stack.Item>
								<Stack.Item
									grow
									mixin={{
										paddingBlockEnd: 4,
									}}
								>
									<TaxiDateTimePickerFormElement
										name="return"
										renderInModal
										dateRangeStart={dateRangeStart}
										dateRangeEnd={dateRangeEnd}
									/>
								</Stack.Item>
							</>
						))}
					{shouldShowLoadingSkeleton && (
						<Stack.Item
							grow
							mixin={{
								paddingBlockEnd: 4,
							}}
						>
							<SkeletonCarCard />
						</Stack.Item>
					)}
					{isReturnResultComplete && (
						<Stack.Item
							grow
							mixin={{
								paddingBlockEnd: 4,
							}}
						>
							<AddReturnCarCard
								carTypeResultReference={carTypeResultReference}
								returnResult={returnResult}
								lang={lang}
							/>
						</Stack.Item>
					)}
					{isReturnAdded && !isLoadingReturn && (
						<Stack.Item
							grow
							mixin={{
								paddingBlockEnd: 4,
							}}
						>
							<Text variant="body_2">
								{t("rides_web_add_a_return_round_trip_price_numerical", {
									variables: {
										price: formatCurrency(totalPrice, returnResult?.currency, lang),
									},
								})}
							</Text>
						</Stack.Item>
					)}
					<Stack.Item grow>
						<Button
							text={
								shouldShowAddReturnButton
									? t("rides_web_add_a_return_modal_title")
									: t("rides_web_add_a_return_modal_cta")
							}
							wide
							size="large"
							onClick={shouldShowAddReturnButton ? handleSubmit : checkPriceClicked}
							disabled={shouldShowAddReturnButton ? !!isLoadingReturn : false}
							attributes={{
								"data-testid": shouldShowAddReturnButton ? "add_return_journey_button" : "check_price_button",
							}}
						/>
					</Stack.Item>
				</Stack>
			)}
		</SheetContainer>
	);
};
