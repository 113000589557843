export const SEARCH_FORM_PARAMETERS_EVENT = "MFESearchFormParameters";
export const SEARCH_FORM_HAS_HEADER_EVENT = "MFESearchFormHasHeader";
export const CLOSE_ALL_RESULTS_EVENT = "CLOSE_ALL_RESULTS";
export const FORM_TYPES = {
	SINGLE_SEARCH: "SINGLE_SEARCH",
	RETURN_SEARCH: "RETURN_SEARCH",
};

export const SEARCH_TYPES = {
	ONEWAY: "oneway",
	RETURN: "return",
};
