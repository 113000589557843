import React from "react";
import { TaxiCarCard } from "@btransport/taxi-car-card-component";
import { useTranslations } from "@bookingcom/lingojs-react";
import { transportTypeMapping } from "@btransport/rides-transport-types";
import type { USP } from "@btransport/taxi-car-card-component/dist/types";
import { formatCurrency } from "../../utilities/format-currency";
import { taxiTranslation } from "../../utilities/taxi-type-translations";
import { getUSPsToShow } from "../UniqueSellingPoints";

export interface AddReturnCarCardProps {
	carTypeResultReference: number;
	lang: string;
	returnResult: {
		bags: number;
		carDetails: {
			description: string;
		};
		currency: string;
		maxPassenger: number;
		meetAndGreet: boolean;
		originalPrice: number;
		price: number;
	};
}

export const AddReturnCarCard: React.FC<AddReturnCarCardProps> = ({ carTypeResultReference, returnResult, lang }) => {
	const { translate: t } = useTranslations();

	const listOfUsps: Array<USP> = getUSPsToShow(returnResult?.meetAndGreet, {
		freeCancellation: t("gt_mig_rides_web_search_results_search-results_usp-free-cancellation"),
		triedAndTrusted: t("gt_mig_rides_web_search_results_search-results_usp-tried-and-trusted"),
		meetAndGreet: t("gt_mig_rides_web_search_results_search-results_usp-meet-greet-title"),
	});

	const transportType = transportTypeMapping(carTypeResultReference)?.transport_type;

	return (
		<TaxiCarCard
			carTypeResultReference={carTypeResultReference}
			dataTest="taxi-card-wrapper__car-card--returns-banner"
			numberOfPassengers={t("gt_mig_rides_web_search_results_search-results_passenger-capacity", {
				variables: {
					number_of_passengers: returnResult?.maxPassenger ?? "",
					ibex_search_passengers_placeholder: "",
				},
			})}
			numberOfSuitcases={t("gt_mig_rides_web_search_results_search-results_luggage-capacity", {
				variables: { number_of_suitcases: returnResult?.bags?.toString() ?? "" },
			})}
			originalPrice={
				returnResult?.originalPrice
					? formatCurrency(returnResult.originalPrice, returnResult?.currency, lang)
					: undefined
			}
			price={`+${formatCurrency(returnResult?.price, returnResult?.currency, lang)}`}
			suitcaseDepth={t("gt_mig_rides_web_search_results_search-results_luggage-tooltip_depth")}
			suitcaseHeight={t("gt_mig_rides_web_search_results_search-results_luggage-tooltip_height")}
			suitcaseSubTitle={t("gt_mig_rides_web_search_results_search-results_luggage-tooltip_subtitle", {
				variables: { luggage_capacity: returnResult?.bags?.toString() ?? "" },
			})}
			suitcaseTitle={t("gt_mig_rides_web_search_results_search-results_luggage-tooltip_title")}
			suitcaseWidth={t("gt_mig_rides_web_search_results_search-results_luggage-tooltip_width")}
			taxiTitle={t(taxiTranslation(transportType))}
			taxiType={transportTypeMapping(carTypeResultReference)?.transport_type}
			usps={listOfUsps}
			priceBottomRightPlacement
		/>
	);
};
