import { padNumber } from "./generic";

export const MINUTE_INCREMENTS = 5;

/**
 * Gets the date +30 minutes from now (rounded up to nearest 5 minute)
 */
export const getMinValidDateTime = (): Date => {
	const minDate = new Date();
	minDate.setSeconds(0);
	minDate.setMilliseconds(0);
	minDate.setMinutes(minDate.getMinutes() + 30);
	const timeToReturn = new Date(minDate.getTime());
	const roundedUpToNearestFiveMinute = Math.ceil(timeToReturn.getMinutes() / MINUTE_INCREMENTS) * MINUTE_INCREMENTS;
	timeToReturn.setMinutes(roundedUpToNearestFiveMinute);

	return new Date(timeToReturn);
};

export const isFutureDateTime = (date: Date, inComparisonTo: Date): boolean =>
	date.getTime() >= inComparisonTo.getTime();

export const getTimeIncrements = (): Array<{ text: string; value: string }> => {
	const toReturn = [];
	for (let hour = 0; hour < 24; hour += 1) {
		for (let minute = 0; minute < 60 / MINUTE_INCREMENTS; minute += 1) {
			const time = `${padNumber(hour)}:${padNumber(minute * MINUTE_INCREMENTS)}`;
			toReturn.push({
				text: time,
				value: time,
			});
		}
	}

	return toReturn;
};

export const optionIncrementor = (length: number, increment: number): Array<{ text: string; value: string }> => {
	const toReturn = [];
	for (let i = 0; i < length; i += 1) {
		const minute = `${padNumber(i * increment)}`;
		toReturn.push({
			text: minute,
			value: minute,
		});
	}

	return toReturn;
};

export const getTimeOptions = (
	date: string,
	times: Array<{ text: string; value: string }>,
): Array<{ text: string; value: string }> => {
	const now = getMinValidDateTime();
	return times.filter((time) => new Date(`${date} ${time.value}`) >= now);
};

const allHourOptions = optionIncrementor(24, 1);
const allMinuteOptions = optionIncrementor(60 / MINUTE_INCREMENTS, MINUTE_INCREMENTS);

export const getHourOptions = (dateSelected: string): Array<{ text: string; value: string }> => {
	const now = getMinValidDateTime();
	now.setMinutes(0);
	const options = allHourOptions.filter((time) => {
		return new Date(`${dateSelected}T${time.value}:00`) >= now;
	});
	return options;
};

export const getMinuteOptions = (dateSelected: string, time: string): Array<{ text: string; value: string }> => {
	const now = getMinValidDateTime();
	const hour = Math.max(parseInt(time.split(":")[0], 10), now.getHours());

	return allMinuteOptions.filter((option) => {
		return new Date(`${dateSelected}T${hour}:${option.value}`) >= now;
	});
};
