import { useEffect } from "react";

export const useKeyPress = (keys: { [key: string]: (e: KeyboardEvent) => void }): void => {
	const onKeyPressDownEvent = (e: KeyboardEvent): void => {
		if (!keys[e.key]) return;

		keys[e.key](e);
	};

	useEffect(() => {
		document.addEventListener("keydown", onKeyPressDownEvent);

		return (): void => {
			document.removeEventListener("keydown", onKeyPressDownEvent);
		};
	});
};
