import * as React from "react";
import type { USP } from "@btransport/taxi-car-card-component/dist/types";
import { CheckmarkIcon, ThumbsUpIcon } from "@bookingcom/bui-assets-react/streamline";
import { Icon } from "@bookingcom/bui-react";

export const MeetAndGreet: React.FC = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.4953 15C16.6535 13.5499 14.3681 12.75 12.0001 12.75C9.63208 12.75 7.34658 13.5499 5.50479 15H3C2.41292 15 1.90462 15.3373 1.65827 15.8286C0.702697 16.0884 0 16.9621 0 18V21C0 22.0379 0.702695 22.9116 1.65827 23.1714C1.90462 23.6627 2.41292 24 3 24H21C21.5871 24 22.0954 23.6627 22.3417 23.1714C23.2973 22.9116 24 22.0379 24 21V18C24 16.9621 23.2973 16.0884 22.3417 15.8286C22.0954 15.3373 21.5871 15 21 15H18.4953ZM3.92708 22.5H20.0729C19.7166 22.1019 19.5 21.5763 19.5 21V18C19.5 17.4237 19.7166 16.8981 20.0729 16.5H3.92708C4.28336 16.8981 4.5 17.4237 4.5 18V21C4.5 21.5763 4.28336 22.1019 3.92708 22.5ZM15.597 15C14.473 14.51 13.25 14.25 12.0001 14.25C10.7502 14.25 9.52711 14.51 8.40316 15H15.597ZM21 18C21 17.5858 21.3358 17.25 21.75 17.25C22.1642 17.25 22.5 17.5858 22.5 18V21C22.5 21.4142 22.1642 21.75 21.75 21.75C21.3358 21.75 21 21.4142 21 21V18ZM2.25 17.25C1.83579 17.25 1.5 17.5858 1.5 18V21C1.5 21.4142 1.83579 21.75 2.25 21.75C2.66421 21.75 3 21.4142 3 21V18C3 17.5858 2.66421 17.25 2.25 17.25Z"
				fill="inherit"
			/>
			<path
				d="M5.25 19.5C5.25 19.0858 5.58579 18.75 6 18.75H18C18.4142 18.75 18.75 19.0858 18.75 19.5C18.75 19.9142 18.4142 20.25 18 20.25H6C5.58579 20.25 5.25 19.9142 5.25 19.5Z"
				fill="inherit"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18 6C18 9.31371 15.3137 12 12 12C8.68629 12 6 9.31371 6 6C6 2.6863 8.6863 0 12 0C15.3137 0 18 2.68629 18 6ZM7.5 6C7.5 8.48528 9.51472 10.5 12 10.5C14.4853 10.5 16.5 8.48529 16.5 6C16.5 3.51472 14.4853 1.5 12 1.5C9.51472 1.5 7.5 3.51472 7.5 6Z"
				fill="inherit"
			/>
		</svg>
	);
};

export const getUSPsToShow = (
	meetAndGreet: boolean,
	translatedContent: {
		freeCancellation: string;
		meetAndGreet: string;
		triedAndTrusted: string;
	},
): USP[] => {
	const USPsToShow: USP[] = [
		{
			icon: <CheckmarkIcon />,
			text: translatedContent.freeCancellation,
		},
	];

	if (!meetAndGreet) {
		USPsToShow.push({
			icon: <ThumbsUpIcon />,
			text: translatedContent.triedAndTrusted,
		});

		return USPsToShow;
	}

	USPsToShow.push({
		icon: <Icon size="small" color="neutral" svg={<MeetAndGreet />} />,
		text: translatedContent.meetAndGreet,
	});

	return USPsToShow;
};
