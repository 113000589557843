import React from "react";
import { useTranslations } from "@bookingcom/lingojs-react";
import { ChangeArgs } from "@bookingcom/bui-react/types/global";
import { Option } from "@bookingcom/bui-react/components/InputSelect/InputSelect.types";
import { Icon } from "@bookingcom/bui-react";
import PersonHalfIcon from "@bookingcom/bui-assets-react/streamline/PersonHalfIcon";
import { setPassengers } from "../../actions/searchForm";
import { useSearchForm } from "../../hooks/useSearchForm";
import { TaxiInputSelect } from "../TaxiInputSelect";
import { setMaxPassengers } from "../../utilities/set-max-passengers";

export interface TaxiPassengersProps {
	maxPassengers?: number;
}

export const TaxiPassengers: React.FC<TaxiPassengersProps> = (props) => {
	const { state, dispatch } = useSearchForm();
	const { translate: t } = useTranslations();

	const getOptionTextByNumber = (num: number): string => {
		if (state.screenSize.isLarge) return `${num}`;

		return num === 1
			? `1 ${t("gt_mig_rides_web_search_form_search_passenger")}`
			: `${num} ${t("gt_mig_rides_web_search_form_search_passengers")}`;
	};

	const createOptions = (): Option[] => {
		const disabledOption = {
			text: t("gt_mig_rides_web_search_form_search_no-of-passengers"),
			value: "0",
			disabled: true,
		};

		const options = Array.from({ length: setMaxPassengers(props.maxPassengers) }).map((_, num) => ({
			text: getOptionTextByNumber(num + 1),
			value: `${num + 1}`,
		}));

		return [disabledOption, ...options];
	};

	const onChange = (event: ChangeArgs<string, HTMLSelectElement>): void => dispatch(setPassengers(event.value));

	return (
		<TaxiInputSelect
			name="passengers"
			ariaLabel={t("gt_mig_rides_web_search_form_search_no-of-passengers")}
			options={createOptions()}
			value={state.passengers}
			icon={<Icon svg={<PersonHalfIcon />} color="neutral" />}
			onChange={onChange}
		/>
	);
};
