const STANDARD_FALLBACK = "gt_mig_rides_web_search_results_taxi-type_standard";

export const SRM_TRANSPORT_TYPE_SUPPORTED_TRANSLATIONS = {
	"bus": STANDARD_FALLBACK,
	"electric-luxury": "gt_mig_rides_web_search_results_taxi-type_electric-luxury",
	"electric-standard": "gt_mig_rides_web_search_results_taxi-type_electric-standard",
	"executive": "gt_mig_rides_web_search_results_taxi-type_executive",
	"executive-people-carrier": "gt_mig_rides_web_search_results_taxi-type_executive-people-carrier",
	"large-people-carrier": "gt_mig_rides_web_search_results_taxi-type_large-people-carrier",
	"luxury": "gt_mig_rides_web_search_results_taxi-type_luxury",
	"minibus": "gt_mig_rides_web_search_results_taxi-type_minibus",
	"people-carrier": "gt_mig_rides_web_search_results_taxi-type_people-carrier",
	"shared-shuttle": STANDARD_FALLBACK,
	"standard": STANDARD_FALLBACK,
	"train": STANDARD_FALLBACK,
	"water-taxi": STANDARD_FALLBACK,
};

export const taxiTranslation = (typeForResultReference: string): string => {
	return SRM_TRANSPORT_TYPE_SUPPORTED_TRANSLATIONS[typeForResultReference];
};
