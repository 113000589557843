import { MutableRefObject, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { LARGE_SCREEN, MEDIUM_SCREEN } from "@bookingcom/bui-react/utilities/screen";
import { useIsomorphicLayoutEffect } from "../utilities/use-isomorphic-layout-effect";

export type ContainerSize = {
	isLarge: boolean;
	isMedium: boolean;
	isSmall: boolean;
};

/**
 * @function useContainerResize
 * You can use this hook to control the layout of your components based on the
 * width of a container instead of the viewport of the window. If you want to
 * control the layout of your components based on the viewport use BUI's
 * useViewport hook instead. It's recommended that you do not use any media
 * quires to control the layout but instead scope them with classes. For example
 * if the size of the container was 'medium', .MD .some-class__name.
 *
 * @return {ContainerSize}
 *
 * isSmall will be returned true when the container width is less than 576px.
 * isMedium will be returned true when the container width is more than or equal
 * to 576px and less than 992px.
 * isLarge will be returned true when the container width is more than 992px.
 *
 * */
export const useContainerResize = <ElementType = HTMLElement | null>(
	elementToListenTo: MutableRefObject<ElementType>,
): ContainerSize => {
	const [containerSize, setContainerSize] = useState<ContainerSize>({
		isSmall: true,
		isMedium: false,
		isLarge: false,
	});

	const updateContainerSize = (width: number): void => {
		setContainerSize({
			isSmall: width < MEDIUM_SCREEN,
			isMedium: width >= MEDIUM_SCREEN && width < LARGE_SCREEN,
			isLarge: width >= LARGE_SCREEN,
		});
	};

	const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
		let width = 0;

		entries.forEach((entry) => {
			width = entry.contentRect.width;
		});

		updateContainerSize(width);
	});

	useIsomorphicLayoutEffect(() => {
		const containerElement = elementToListenTo.current as unknown as HTMLElement;

		updateContainerSize(containerElement.offsetWidth);
		resizeObserver.observe(containerElement as Element);

		return () => {
			resizeObserver.unobserve(containerElement as Element);
		};
	}, [elementToListenTo]);

	return containerSize;
};
