import React, { useRef, useState, useEffect } from "react";
import { getOrchestrator } from "@bookingcom/web-page-orchestrator";
import { Button, Icon, SheetContainer } from "@bookingcom/bui-react";
import CalendarIcon from "@bookingcom/bui-assets-react/streamline/CalendarIcon";
import classnames from "classnames";
import { RidesPubSub } from "@btransport/rides-pubsub-library";
import { trackEvent } from "@btransport/rides-tracking-publisher";
import { useTranslations } from "@bookingcom/lingojs-react";
import { useSearchForm } from "../../hooks/useSearchForm";
import { TaxiDateTimePicker } from "../TaxiDateTimePicker";
import {
	getDisplayDate,
	getFormattedDate,
	getFormattedTime,
	getHoursValue,
	getMinutesValue,
} from "../TaxiDateTimePicker/dateTimeUtil";
import { setReturnJourney, toggleError } from "../../actions/searchForm";
import { TaxiErrorTooltip } from "../TaxiErrorTooltip";
import { TaxiDateTimeKeyboardPicker } from "../TaxiDateTimeKeyboardPicker";
import { CLOSE_ALL_RESULTS_EVENT } from "../../constants/events";
import { TEST_IDS } from "./TaxiDateTimePickerFormElement.constants";
import styles from "./TaxiDateTimePickerFormElement.module.css";
import { HandleDateTimeChange } from "../../utilities/handle-date-time-change";

export interface TaxiDateTimePickerFormElementProps {
	dateRangeEnd?: Date;
	dateRangeStart?: Date;
	name: "oneway" | "return";
	renderInModal?: boolean;
}

export const TaxiDateTimePickerFormElement: React.FC<TaxiDateTimePickerFormElementProps> = (props) => {
	const { translate: t } = useTranslations();
	const containerRef = useRef<HTMLDivElement>();
	const { state, dispatch } = useSearchForm();
	const [isVisible, setIsVisible] = useState(false);
	const [isKeyboardDateTimePickerActive, setIsKeyboardDateTimePickerActive] = useState(false);
	const { dateRangeStart, dateRangeEnd, renderInModal } = props;

	const isOneWay = props.name === "oneway";
	const dateTime = isOneWay ? state.outboundDateTime : state.returnDateTime;
	const isReturnDisabled = props.name === "return" && !state.isReturn;
	const hasError = state.hasReturnDateError && state.isReturn && props.name === "return";
	const isMobile = state.screenSize.isSmall;

	const returnBannerToggle = (): void => {
		dispatch(setReturnJourney(true));
	};

	const handleToggle = (): void => {
		if ((state.enableClickToReturn || state.enableLandingPageVariant) && isReturnDisabled && !isOneWay) {
			dispatch(setReturnJourney(true));
			trackEvent({
				category: "SearchComponent",
				action: "Click",
				label: "Add a return",
				customDimensions: window.GACustomDimensions || [],
			});
		}

		RidesPubSub.publish(CLOSE_ALL_RESULTS_EVENT);
		dispatch(toggleError("hasReturnDateError", false));
		setIsVisible(!isVisible);
	};

	const { handleDateChange, handleTimeChange } = HandleDateTimeChange(props.name, dateTime);

	const orchestrator = getOrchestrator();

	useEffect(() => {
		if (props.name === "return") {
			orchestrator?.runCommand("UPDATE_RETURN_DATE_TIME", {
				returnDate: getFormattedDate(dateTime),
				returnTime: getFormattedTime(dateTime),
			});
		}
	}, [handleDateChange, handleTimeChange]);

	useEffect(() => {
		if (props.name === "return") {
			orchestrator?.on("TRIGGER_SEARCH_FORM_RETURN", async (): Promise<void> => {
				returnBannerToggle();
			});
		}
	}, [orchestrator]);

	const onDateTimeKeyboardPickerChange = (currentState: boolean): void => {
		RidesPubSub.publish(CLOSE_ALL_RESULTS_EVENT);
		setIsKeyboardDateTimePickerActive(currentState);
	};

	return (
		<div
			className={`${styles["taxi-date-time-picker-form-element"]} ${renderInModal && styles["taxi-date-time-picker-form-element-modal"]}`}
			data-testid={`${TEST_IDS.dateTimePickerFormPrefix}-${props.name}`}
			ref={containerRef}
		>
			{(!isReturnDisabled || state.enableClickToReturn || state.enableLandingPageVariant) && (
				<TaxiDateTimeKeyboardPicker name={props.name} onFocusChange={onDateTimeKeyboardPickerChange} />
			)}
			{!isKeyboardDateTimePickerActive && (
				<Button
					text={
						isReturnDisabled && !isOneWay
							? t("gt_mig_rides_web_search_form_search_add-return")
							: getDisplayDate(dateTime, state.lang)
					}
					className={classnames(styles[`taxi-date-time-picker-form-element--button`], {
						[styles[`taxi-date-time-picker-form-element--button-error`]]: hasError,
						[styles[`taxi-date-time-picker-form-element--button-return-experiment`]]:
							(state.enableClickToReturn || state.enableLandingPageVariant) && isReturnDisabled,
					})}
					icon={<Icon svg={<CalendarIcon />} color={isReturnDisabled ? undefined : "neutral"} />}
					type="button"
					onClick={handleToggle}
					disabled={!(state.enableClickToReturn || state.enableLandingPageVariant) && isReturnDisabled}
					attributes={{
						"data-testid": `${TEST_IDS.dateTimePickerFormButtonPrefix}-${props.name}`,
						"tabIndex": -1,
					}}
				/>
			)}
			{hasError && state.screenSize.isLarge && (
				<TaxiErrorTooltip message={t("gt_mig_rides_web_search_form_search_time-error")} containerRef={containerRef} />
			)}
			<TaxiDateTimePicker
				isVisible={isVisible && !isMobile}
				name={props.name}
				selectedDate={dateTime}
				hours={getHoursValue(dateTime)}
				minutes={getMinutesValue(dateTime)}
				onDateChange={handleDateChange}
				onTimeChange={handleTimeChange}
				onBlur={() => setIsVisible(false)}
				isMobile={isMobile}
				dateRangeStart={dateRangeStart}
				dateRangeEnd={dateRangeEnd}
				renderInModal={renderInModal}
			/>
			{isVisible && isMobile ? (
				<SheetContainer
					position="bottom"
					className={styles["taxi-date-time-picker-form-element_drawer-container"]}
					closeAriaLabel={t("gt_mig_rides_web_search_form_calendar_close")}
					onCloseTrigger={() => {
						setIsVisible(false);
					}}
					active={isVisible && isMobile}
					attributes={{
						"data-testid": TEST_IDS.dateTimePickerFormDrawer,
					}}
				>
					<TaxiDateTimePicker
						isVisible
						name={props.name}
						selectedDate={dateTime}
						hours={getHoursValue(dateTime)}
						minutes={getMinutesValue(dateTime)}
						onDateChange={handleDateChange}
						onTimeChange={handleTimeChange}
						onBlur={() => setIsVisible(false)}
						isMobile={isMobile}
						classNames={[styles["auto-width"]]}
						dateRangeStart={dateRangeStart}
						dateRangeEnd={dateRangeEnd}
					/>
					<Button
						text={t("gt_mig_rides_web_search_form_search_keyboard-date-done")}
						size="large"
						onClick={() => {
							setIsVisible(false);
						}}
						attributes={{
							"data-testid": TEST_IDS.dateTimePickerFormDrawerButton,
						}}
						wide
					/>
				</SheetContainer>
			) : null}
		</div>
	);
};
