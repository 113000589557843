import type { SearchFormProviderProps } from "../contexts/SearchFormContext/types";
import { DEFAULT_MAX_NO_OF_PASSENGERS } from "../constants/defaults";

function isPassengersValid(passengers: SearchFormProviderProps["passengers"]): boolean {
	if (!passengers) {
		return false;
	}

	const passengersAsNumber = parseInt(passengers, 10);

	if (Number.isNaN(passengersAsNumber)) {
		return false;
	}

	if (passengersAsNumber > DEFAULT_MAX_NO_OF_PASSENGERS) {
		return false;
	}

	return passengersAsNumber >= 1;
}

function isDateTimeValid(dateTime?: string | undefined): boolean {
	if (!dateTime) {
		return false;
	}

	const pickUpDateTimeAsDate = new Date(dateTime);

	// eslint-disable-next-line no-restricted-globals
	return !isNaN(pickUpDateTimeAsDate.getTime());
}

export { isPassengersValid, isDateTimeValid };
