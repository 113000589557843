import type { Option } from "@bookingcom/bui-react/components/InputSelect/InputSelect.types";
import { DEFAULT_LANG } from "../../constants/defaults";

export const getMinDate = (): Date => new Date();

export const getMaxDate = (): Date => new Date(getMinDate().setMonth(getMinDate().getMonth() + 13));

export const getDefaultDate = (addDays: number): Date => {
	const baseDate = new Date(getMinDate().setDate(getMinDate().getDate() + addDays));
	baseDate.setHours(12, 0, 0, 0);

	return baseDate;
};

export const getOptionTextByNumber = (num: number): string => (num <= 9 ? `0${num}` : `${num}`);

export const generateHours = (): Array<Option> =>
	Array.from({ length: 24 }).map((_, num) => ({
		text: getOptionTextByNumber(num),
		value: `${num}`,
	}));

export const generateMinutes = (): Array<Option> =>
	Array.from({ length: 12 }).map((_, num) => ({
		text: getOptionTextByNumber(num * 5),
		value: `${num * 5}`,
	}));

export const getDisplayDate = (date: Date, lang: string = DEFAULT_LANG): string => {
	const weekday = new Date(date).toLocaleString(lang, {
		weekday: "short",
	});

	const month = new Intl.DateTimeFormat(lang, {
		month: "short",
	}).format(date);

	const day = new Intl.DateTimeFormat(lang, {
		day: "2-digit",
	}).format(date);

	const hours = getOptionTextByNumber(date.getHours());
	const minutes = getOptionTextByNumber(date.getMinutes());

	return `${weekday} ${day}, ${month}, ${hours}:${minutes}`;
};

export const getHoursValue = (date: Date): string => `${date.getHours()}`;

export const getMinutesValue = (date: Date): string => `${date.getMinutes()}`;

export const getFormattedDate = (date: Date): string =>
	`${date.getFullYear()}-${getOptionTextByNumber(date.getMonth() + 1)}-${getOptionTextByNumber(date.getDate())}`;

export const getFormattedTime = (date: Date): string =>
	`${getOptionTextByNumber(date.getHours())}:${getOptionTextByNumber(date.getMinutes())}`;
