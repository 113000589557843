import { registerDynamicTags, t } from "@bookingcom/lingojs-core";

export const registerLingoTags = (): void => {
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_close"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_day-friday"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_day-monday"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_day-saturday"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_day-sunday"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_day-thursday"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_day-tuesday"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_day-wednesday"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_month-april"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_month-august"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_month-december"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_month-february"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_month-january"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_month-july"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_month-june"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_month-march"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_month-may"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_month-november"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_month-october"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_month-september"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_calendar_pick-up-time"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_return_add-return"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_return_heading-date-time"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_return_heading-date-time-subheading"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_route-panner_header"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_route-panner_results"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_add-return"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_close-route-planner"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_destination-location-placeholder"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_drop-off"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_drop-off-error"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_drop-off-location"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_journey-type"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_keyboard-date-done"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_keyboard-date-dropoff-day"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_keyboard-date-dropoff-hour"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_keyboard-date-dropoff-minute"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_keyboard-date-dropoff-month"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_keyboard-date-dropoff-year"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_keyboard-date-pickup-day"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_keyboard-date-pickup-hour"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_keyboard-date-pickup-minute"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_keyboard-date-pickup-month"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_keyboard-date-pickup-year"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_list-box-instructions"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_no-of-passengers"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_one-way"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_passenger"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_passengers"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_pick-up"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_pick-up-error"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_pick-up-location"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_pick-up-time"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_pickup-location-placeholder"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_powered-by-google"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_results-found"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_return"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_search"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_swap-pickup-and-dropoff"));
	registerDynamicTags(t("gt_mig_rides_web_search_form_search_time-error"));
	registerDynamicTags(t("gt_mig_rides_search-form_update-button"));
	registerDynamicTags(t("rides_web_add_a_return_banner_title"));
	registerDynamicTags(t("rides_web_add_a_return_banner_cta"));
	registerDynamicTags(t("rides_web_add_a_return_modal_title"));
	registerDynamicTags(t("rides_web_add_a_return_modal_subtitle"));
	registerDynamicTags(t("rides_web_add_a_return_date_time_label"));
	registerDynamicTags(t("rides_web_add_a_return_modal_cta"));
	registerDynamicTags(t("rides_web_add_a_return_round_trip_price_numerical"));
	registerDynamicTags(t("rides_web_add_a_return_round_trip_price_string"));
	registerDynamicTags(t("gt_mig_rides_web_search_results_taxi-type_executive"));
	registerDynamicTags(t("gt_mig_rides_web_search_results_taxi-type_executive-people-carrier"));
	registerDynamicTags(t("gt_mig_rides_web_search_results_taxi-type_luxury"));
	registerDynamicTags(t("gt_mig_rides_web_search_results_taxi-type_people-carrier"));
	registerDynamicTags(t("gt_mig_rides_web_search_results_taxi-type_large-people-carrier"));
	registerDynamicTags(t("gt_mig_rides_web_search_results_taxi-type_minibus"));
	registerDynamicTags(t("gt_mig_rides_web_search_results_taxi-type_standard"));
	registerDynamicTags(t("gt_mig_rides_web_search_results_taxi-type_electric-standard"));
	registerDynamicTags(t("gt_mig_rides_web_search_results_taxi-type_electric-luxury"));
	registerDynamicTags(t("gt_mig_rides_web_search_results_search-results_usp-free-cancellation"));
	registerDynamicTags(t("gt_mig_rides_web_search_results_search-results_usp-tried-and-trusted"));
	registerDynamicTags(t("gt_mig_rides_web_search_results_search-results_usp-meet-greet-title"));
	registerDynamicTags(t("gt_mig_rides_route-summary_estimated-time"));
	registerDynamicTags(t("short_date_with_weekday_time"));
	registerDynamicTags(t("time_format", "1", "name"));
	registerDynamicTags(t("rides_web_add_a_return_no_results_modal_subtitle"));
	registerDynamicTags(t("rides_web_add_a_return_no_results_modal_title"));
	registerDynamicTags(t("a11y_rides_web_add_a_return_modal_close_cta"));
	registerDynamicTags(t("android_taxis_flights_number_close"));
};
