import type { SelectedLocationType } from "../../../contexts/SearchFormContext";
import type { AutoCompleteSearchBody, AutocompleteResponseInterface } from "./types";

export const taxiAutocompleteSearch = async (
	baseUrl: string,
	query: string,
	language: string,
	locationBias?: {
		latitude: SelectedLocationType["latitude"];
		longitude: SelectedLocationType["longitude"];
	},
	preferLocationApi: boolean = false,
): Promise<AutocompleteResponseInterface> => {
	const body: AutoCompleteSearchBody = {
		query,
		language,
	};

	if (locationBias?.latitude && locationBias.longitude) {
		body.locationBias = {
			...locationBias,
			radius: 150000,
			boostValue: 6,
		};
	}

	const options = {
		method: "POST",
		body: JSON.stringify(body),
		headers: {
			"Content-Type": "application/json",
		},
	};

	let url = `${baseUrl}/autocomplete`;
	if (preferLocationApi) {
		url += "?preferLocationApi=true";
	}

	const response = await fetch(url, options);

	return response.json();
};
