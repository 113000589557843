import React from "react";
import { Calendar } from "@bookingcom/bui-react";
import { useTranslations } from "@bookingcom/lingojs-react";
import { getMaxDate, getMinDate } from "../TaxiDateTimePicker/dateTimeUtil";

export type CalendarWrapperProps = {
	dataTestId: string;
	dateRangeEnd?: Date;
	dateRangeStart?: Date;
	onDateChange: ({ changedDate }: { changedDate: Date }) => void;
	selectedDate: Date;
};

export const CalendarWrapper: React.FC<CalendarWrapperProps> = ({
	dateRangeEnd,
	dateRangeStart,
	onDateChange,
	selectedDate,
	dataTestId,
}) => {
	const { translate: t } = useTranslations();

	return (
		<Calendar
			dayNames={{
				friday: t("gt_mig_rides_web_search_form_calendar_day-friday"),
				monday: t("gt_mig_rides_web_search_form_calendar_day-monday"),
				saturday: t("gt_mig_rides_web_search_form_calendar_day-saturday"),
				sunday: t("gt_mig_rides_web_search_form_calendar_day-sunday"),
				thursday: t("gt_mig_rides_web_search_form_calendar_day-thursday"),
				tuesday: t("gt_mig_rides_web_search_form_calendar_day-tuesday"),
				wednesday: t("gt_mig_rides_web_search_form_calendar_day-wednesday"),
			}}
			monthNames={{
				april: t("gt_mig_rides_web_search_form_calendar_month-april"),
				august: t("gt_mig_rides_web_search_form_calendar_month-august"),
				december: t("gt_mig_rides_web_search_form_calendar_month-december"),
				february: t("gt_mig_rides_web_search_form_calendar_month-february"),
				january: t("gt_mig_rides_web_search_form_calendar_month-january"),
				july: t("gt_mig_rides_web_search_form_calendar_month-july"),
				june: t("gt_mig_rides_web_search_form_calendar_month-june"),
				march: t("gt_mig_rides_web_search_form_calendar_month-march"),
				may: t("gt_mig_rides_web_search_form_calendar_month-may"),
				november: t("gt_mig_rides_web_search_form_calendar_month-november"),
				october: t("gt_mig_rides_web_search_form_calendar_month-october"),
				september: t("gt_mig_rides_web_search_form_calendar_month-september"),
			}}
			singleDate
			minDate={dateRangeStart || getMinDate()}
			maxDate={dateRangeEnd || getMaxDate()}
			defaultStartDate={selectedDate}
			baseDate={dateRangeStart || selectedDate}
			onDateChange={onDateChange}
			attributes={{
				"data-testid": dataTestId,
			}}
		/>
	);
};
