import { useEffect, useCallback, useState, useRef, RefObject } from "react";
import { InputText, Prop } from "@bookingcom/bui-react";
import { RidesPubSub } from "@btransport/rides-pubsub-library";
import { setResults, clearResults, selectResult, toggleError, toggleRoutePlanner } from "../../actions/searchForm";
import {
	taxiAutocompleteSearch,
	AutocompleteResultInterface,
	LocationBiasLatLong,
} from "../../server/services/taxi-autocomplete";
import { useSearchForm } from "../../hooks/useSearchForm";
import { CLOSE_ALL_RESULTS_EVENT } from "../../constants/events";
import { DEFAULT_LANG } from "../../constants/defaults";
import { LocationTypeInterface } from "../../contexts/SearchFormContext";

export interface SearchLocationAutocompleteInterface {
	closeResults: () => void;
	inputRef: RefObject<HTMLInputElement>;
	onChange: Prop<typeof InputText, "onChange">;
	onFocus: () => void;
	onSelect: (result: AutocompleteResultInterface) => void;
	searchTerm: string;
	showResults: boolean;
}

const getLocationBias = (
	name: string,
	pickup: LocationTypeInterface,
	dropoff: LocationTypeInterface,
): LocationBiasLatLong | undefined => {
	if (name === "pickup" && dropoff.selected) {
		return {
			latitude: dropoff.selected.latitude,
			longitude: dropoff.selected.longitude,
		};
	}

	if (name === "dropoff" && pickup.selected) {
		return {
			latitude: pickup.selected.latitude,
			longitude: pickup.selected.longitude,
		};
	}

	return undefined;
};

export const useTaxiAutocomplete = (name: "pickup" | "dropoff", timeout = 200): SearchLocationAutocompleteInterface => {
	const { state, dispatch } = useSearchForm();
	const [searchTerm, setSearchTerm] = useState("");
	const [showResults, setShowResults] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const onChange = ({ value }: { value: string }): void => {
		setSearchTerm(value);

		if (state[name].selected) {
			dispatch(selectResult(name));
		}

		if (value.length < 2) {
			dispatch(clearResults(name));
		}

		if (state[name].results.length > 0) {
			setShowResults(true);
		}
	};

	const onFocus = (): void => {
		RidesPubSub.publish(CLOSE_ALL_RESULTS_EVENT);
		dispatch(toggleError(name, false));

		if (!state.screenSize.isLarge && !state.showRoutePlanner) {
			dispatch(toggleRoutePlanner(name));
		}
	};

	const closeResults = (): void => {
		setShowResults(false);
	};

	const onSelect = (result: AutocompleteResultInterface): void => {
		dispatch(selectResult(name, result));
		setShowResults(false);
	};

	const makeRequest = useCallback(async () => {
		const data = await taxiAutocompleteSearch(
			state.autocompleteBaseUrl,
			searchTerm,
			(state.lang || DEFAULT_LANG).split("-")[0],
			getLocationBias(name, state.pickup, state.dropoff),
			state.preferLocationApi,
		);

		dispatch(setResults(name, data));

		setShowResults(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, name, searchTerm]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (searchTerm.length >= 2) {
				makeRequest();
			}
		}, timeout);

		return () => {
			clearTimeout(timer);
		};
	}, [makeRequest, searchTerm, timeout]);

	return {
		closeResults,
		inputRef,
		onChange,
		onFocus,
		onSelect,
		searchTerm,
		showResults,
	};
};
