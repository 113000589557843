import React, { useEffect } from "react";
import { RidesPubSub } from "@btransport/rides-pubsub-library";
import { useSearchForm } from "../../hooks/useSearchForm";
import { useTaxiAutocomplete } from "./useTaxiAutocomplete";
import { TaxiAutocompleteSearchBox } from "../TaxiAutocompleteSearchBox";
import { CLOSE_ALL_RESULTS_EVENT } from "../../constants/events";

export interface TaxiAutocompleteProps extends React.Attributes {
	label: string;
	name: "pickup" | "dropoff";
	placeholder: string;
}

export const TaxiAutocomplete: React.FC<TaxiAutocompleteProps> = (props) => {
	const { state } = useSearchForm();
	const { searchTerm, onChange, onFocus, closeResults, onSelect, showResults, inputRef } = useTaxiAutocomplete(
		props.name,
	);

	useEffect(() => {
		const closeAllResults = (): void => {
			closeResults();
		};

		RidesPubSub.subscribe(CLOSE_ALL_RESULTS_EVENT, closeAllResults);

		return () => {
			RidesPubSub.unsubscribe(CLOSE_ALL_RESULTS_EVENT, closeAllResults);
		};
	});

	return (
		<TaxiAutocompleteSearchBox
			name={props.name}
			label={props.label}
			placeholder={props.placeholder}
			value={state[props.name].selected ? state[props.name].selected?.name : searchTerm}
			onChange={onChange}
			onFocus={onFocus}
			onSelect={onSelect}
			isMobile={!state.screenSize.isLarge}
			showResults={showResults}
			results={state[props.name].results}
			hasError={state[props.name].hasError}
			inputRef={inputRef}
			isFocused={state.elementToFocusOn === props.name}
		/>
	);
};
