export const DEFAULT_CURRENCY = "EUR";
const DEFAULT_LOCALE = "en-GB";

/**
 * Intl on Node.js doesn't have languages outside English by default, so for
 * Intl to work server-side with different locales I had to follow guidelines
 * from this page.
 * https://www.basefactor.com/javascript-es6-intl-not-working-properly-when-running-jest-tests
 */
export function formatCurrency(
	amount: number | string,
	currency: string = DEFAULT_CURRENCY,
	locale: string = DEFAULT_LOCALE,
): string {
	const parsedAmount = parseFloat(`${amount}`);
	const options: Intl.NumberFormatOptions =
		currency === "ILS"
			? {
					style: "currency",
					currency,
				}
			: {
					style: "currency",
					currency,
					currencyDisplay: "narrowSymbol",
				};

	if (Number.isNaN(parsedAmount)) {
		throw new TypeError(`Currency: Failed to parse the passed value - "${amount}"`);
	}

	const numberFormat = new Intl.NumberFormat(locale, options);

	return numberFormat.format(parsedAmount);
}
