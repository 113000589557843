import React, { RefObject, useEffect, useRef, useState } from "react";
import { Icon, InputText, HiddenVisually, Prop } from "@bookingcom/bui-react";
import { useTranslations } from "@bookingcom/lingojs-react";
import CheckmarkEmptyIcon from "@bookingcom/bui-assets-react/streamline/CheckmarkEmptyIcon";
import { AutocompleteResultInterface } from "../../server/services/taxi-autocomplete";
import { TaxiAutocompleteSearchDropDown } from "../TaxiAutocompleteSearchDropDown";
import { TaxiErrorTooltip } from "../TaxiErrorTooltip";
import styles from "./TaxiAutocompleteSearchBox.module.css";
import { TEST_IDS } from "./TaxiAutocompleteSearchBox.constants";

export interface TaxiAutocompleteSearchBoxProps extends React.Attributes {
	description?: string;
	hasError: boolean;
	inputRef: RefObject<HTMLInputElement>;
	isFocused?: boolean;
	isMobile?: boolean;
	label: string;
	name: "pickup" | "dropoff";
	onBlur?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
	onChange?: Prop<typeof InputText, "onChange">;
	onFocus?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
	onSelect?: (result: AutocompleteResultInterface) => void;
	placeholder: string;
	results?: Array<AutocompleteResultInterface>;
	showResults?: boolean;
	value?: string;
}

export const TaxiAutocompleteSearchBox: React.FC<TaxiAutocompleteSearchBoxProps> = (props) => {
	const containerRef = useRef<HTMLInputElement>();
	const { translate: t } = useTranslations();
	const [activeDescendant, setActiveDescendant] = useState<string | null>(null);

	const nameMapping = {
		dropoff: "drop-off",
		pickup: "pick-up",
	};

	const formattedName = nameMapping[props.name];

	const onListItemFocusChange = (listItemId: string | null): void => {
		setActiveDescendant(listItemId);
	};

	const formatResultsFound = (): string =>
		t("gt_mig_rides_web_search_form_search_results-found", { variables: { results: props.results?.length || 0 } });

	useEffect(() => {
		if (props.isFocused) {
			props.inputRef.current?.focus();
		}
	}, [props.inputRef, props.isFocused]);

	return (
		<div
			role="combobox"
			aria-expanded={props.showResults}
			aria-owns={`taxi-autocomplete-search-drop-down-${props.name}`}
			aria-haspopup="listbox"
			aria-label={props.label}
			aria-controls={`taxi-autocomplete-search-drop-down-${props.name}`}
			style={{ position: "relative" }}
			ref={containerRef}
		>
			<InputText
				name={props.name}
				id={`${props.name}-input-id`}
				size="large"
				type="text"
				bordered={false}
				className={`${styles["taxi-autocomplete-search-box__container"]} ${
					props.hasError ? styles["taxi-autocomplete-search-box__container-error"] : ""
				}`}
				inputClassName={styles["taxi-autocomplete-search-box__input"]}
				placeholder={props.placeholder}
				value={props.value}
				error={props.hasError}
				onChange={props.onChange}
				onBlur={props.onBlur}
				onFocus={props.onFocus}
				startSlot={
					props.isMobile && (
						<Icon
							svg={<CheckmarkEmptyIcon />}
							size="smaller"
							color="neutral"
							attributes={{
								"data-testid": TEST_IDS.autocompleteCheckmarkEmpty,
							}}
							className={styles["taxi-autocomplete-search-box__icon-container"]}
						/>
					)
				}
				attributes={{
					"data-testid": `${TEST_IDS.autocompleteSearchContainerPrefix}-${props.name}`,
				}}
				inputAttributes={{
					"aria-activedescendant": activeDescendant,
					"aria-autocomplete": "list",
					"aria-controls": `taxi-autocomplete-search-drop-down-${props.name}`,
					"aria-describedby": `${props.name}-a11y-description`,
					"aria-label": `${props.label}`,
					"autoComplete": "off",
					"data-testid": `${TEST_IDS.autocompleteSearchInputPrefix}-${props.name}`,
					"id": `taxi-autocomplete-input-${props.name}`,
					"ref": props.inputRef,
					"title": `${props.value}`,
				}}
			/>

			<HiddenVisually>
				<span id={`${props.name}-a11y-description`}>
					{t("gt_mig_rides_web_search_form_search_list-box-instructions")}
				</span>
			</HiddenVisually>

			{props.results && (
				<HiddenVisually>
					<span aria-live="polite" aria-relevant="all" aria-atomic="true">
						{formatResultsFound()}
					</span>
				</HiddenVisually>
			)}

			<HiddenVisually>
				<div id={`${props.name}-aria-description`} data-testid={TEST_IDS.autocompleteAriaDescription}>
					{props.description || props.label}
				</div>
			</HiddenVisually>

			{props.hasError && !props.isMobile && (
				<TaxiErrorTooltip
					message={t(`gt_mig_rides_web_search_form_search_${[formattedName]}-error`)}
					containerRef={containerRef}
				/>
			)}

			<TaxiAutocompleteSearchDropDown
				results={props.results || []}
				onSelect={props.onSelect as (result: AutocompleteResultInterface) => void}
				showResults={props.showResults || false}
				name={props.name}
				onFocus={onListItemFocusChange}
			/>
		</div>
	);
};
