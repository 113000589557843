import React from "react";
import { Stack, Button, Icon, Title } from "@bookingcom/bui-react";
import ArrowNavLeftIcon from "@bookingcom/bui-assets-react/streamline/ArrowNavLeftIcon";
import { TaxiLocationManager } from "../TaxiLocationManager";
import { TEST_IDS } from "./TaxiRoutePlanner.constants";
import styles from "./TaxiRoutePlanner.module.css";

export interface TaxiRoutePlannerProps {
	closeButtonAriaLabel: string;
	onClose: () => void;
	title: string;
}

export const TaxiRoutePlanner: React.FC<TaxiRoutePlannerProps> = (props) => (
	<div className={styles["taxi-route-planner"]} data-testid={TEST_IDS.routePlanner}>
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="start"
			gap={2}
			className={styles[`taxi-route-planner__header`]}
		>
			<Stack.Item alignSelf="center">
				<Button
					icon={<Icon size="large" svg={<ArrowNavLeftIcon />} />}
					variant="tertiary"
					className={styles[`taxi-route-planner__close-button`]}
					attributes={{
						"data-testid": TEST_IDS.routePlannerClose,
						"aria-label": props.closeButtonAriaLabel,
					}}
					onClick={props.onClose}
					size="large"
				/>
			</Stack.Item>
			<Stack.Item grow alignSelf="center">
				<Title
					className={styles[`taxi-route-planner__header-title`]}
					variant="strong_1"
					title={props.title}
					attributes={{
						"data-testid": TEST_IDS.routePlannerHeader,
					}}
				/>
			</Stack.Item>
		</Stack>
		<div className={styles[`taxi-route-planner__content`]}>
			<TaxiLocationManager />
		</div>
	</div>
);
