import { useSearchForm } from "../../hooks/useSearchForm";
import { swapLocations } from "../../actions/searchForm";

interface TaxiLocationManagerHookInterface {
	isMobile: boolean;
	isSwapDisabled: boolean;
	onClickSwap: () => void;
}

export const useTaxiLocationManager = (): TaxiLocationManagerHookInterface => {
	const { state, dispatch } = useSearchForm();

	const onClickSwap = (): void => {
		dispatch(swapLocations());
	};

	return {
		isMobile: !state.screenSize.isLarge,
		isSwapDisabled: !(!!state.pickup.selected && !!state.dropoff.selected),
		onClickSwap,
	};
};
