import React, { createContext, useMemo, useReducer } from "react";
import { SearchFormContextInterface, SearchFormProviderProps } from "./types";
import { searchFormReducer } from "../../reducers/searchForm";
import { DEFAULT_STATE } from "./SearchFormProvider.constants";
import { createInitialState } from "./create-initial-context";

export const SearchFormContext = createContext<SearchFormContextInterface>({
	state: DEFAULT_STATE,
	dispatch: (): void => {
		/** */
	},
});

export const SearchFormProvider = (props: SearchFormProviderProps): JSX.Element => {
	const initialState = createInitialState(props);

	const [state, dispatch] = useReducer(searchFormReducer, initialState);

	const value = useMemo(
		() => ({
			state,
			dispatch,
		}),
		[state, dispatch],
	);

	return <SearchFormContext.Provider value={value}>{props.children}</SearchFormContext.Provider>;
};
