import React from "react";
import { Box } from "@bookingcom/bui-react";
import { useTranslations } from "@bookingcom/lingojs-react";
import { useSearchForm } from "../../hooks/useSearchForm";
import { TEST_IDS } from "./TaxiErrorContainer.constants";
import styles from "./TaxiErrorContainer.module.css";

export const TaxiErrorContainer = (): JSX.Element | null => {
	const { state } = useSearchForm();

	const { translate: t } = useTranslations();

	if (!state.pickup.hasError && !state.dropoff.hasError && (!state.hasReturnDateError || !state.isReturn)) {
		return null;
	}

	return (
		<Box
			className={styles["taxi-error-container"]}
			attributes={{
				"data-testid": TEST_IDS.errorContainer,
			}}
		>
			{state.pickup.hasError && (
				<p data-testid={`${TEST_IDS.errorContainerMessage}`}>
					{t("gt_mig_rides_web_search_form_search_pick-up-error")}
				</p>
			)}
			{state.dropoff.hasError && (
				<p data-testid={`${TEST_IDS.errorContainerMessage}`}>
					{t("gt_mig_rides_web_search_form_search_drop-off-error")}
				</p>
			)}
			{state.hasReturnDateError && state.isReturn && (
				<p data-testid={`${TEST_IDS.errorContainerMessage}`}>{t("gt_mig_rides_web_search_form_search_time-error")}</p>
			)}
		</Box>
	);
};
