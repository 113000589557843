import React from "react";
import classnames from "classnames";
import { InputSelect } from "@bookingcom/bui-react";
import { ChangeArgs } from "@bookingcom/bui-react/types/global";
import { Option } from "@bookingcom/bui-react/components/InputSelect/InputSelect.types";
import { RidesPubSub } from "@btransport/rides-pubsub-library";
import { CLOSE_ALL_RESULTS_EVENT } from "../../constants/events";
import { TEST_IDS } from "./TaxiInputSelect.constants";
import styles from "./TaxiInputSelect.module.css";
import { useSearchForm } from "../../hooks/useSearchForm";

export interface TaxiInputSelectProps extends React.Attributes {
	ariaLabel: string;
	icon?: JSX.Element;
	name: string;
	onChange?: (event: ChangeArgs<string, HTMLSelectElement>) => void;
	options: Array<Option>;
	value?: string;
}

// TODO: Look into improving Full Height Child ClassName
export const TaxiInputSelect: React.FC<TaxiInputSelectProps> = ({
	ariaLabel,
	icon,
	name,
	onChange,
	options,
	value,
}) => {
	const { state } = useSearchForm();
	const onFocus = (): void => RidesPubSub.publish(CLOSE_ALL_RESULTS_EVENT);

	const classes = classnames(styles["taxi-input-select"], {
		[styles["taxi-input-select--vertical"]]: state.screenSize.isMedium || state.screenSize.isSmall,
	});

	return (
		<div className={classes} data-testid={TEST_IDS.taxiInputSelectWrapper}>
			<InputSelect
				attributes={{ "data-testid": TEST_IDS.taxiInputSelect }}
				className={`${styles["taxi-input-select__input-select"]} ${styles["full-height-child"]}`}
				inputAttributes={{
					"data-testid": `${TEST_IDS.taxiInputSelectInputPrefix}-${name}`,
					"aria-label": ariaLabel,
				}}
				inputClassName={classnames(
					`${styles["taxi-input-select__select"]} ${styles["taxi-input-select__select-container"]}`,
					{
						[styles["taxi-input-select__select--with-icon"]]: icon,
					},
				)}
				name={name}
				onChange={onChange}
				onFocus={onFocus}
				options={options}
				startIcon={icon || undefined}
				value={value}
			/>
		</div>
	);
};
