import React from "react";
import Flyout from "@bookingcom/bui-react/components/_base/Flyout";
import FlyoutArrow from "@bookingcom/bui-react/components/_base/Flyout/FlyoutArrow";
import FlyoutContent from "@bookingcom/bui-react/components/_base/Flyout/FlyoutContent";
import { classNames } from "@bookingcom/bui-core/utilities/classNames";
import tooltipStyles from "@bookingcom/bui-core/css/Tooltip.module.css";
import { TEST_IDS } from "./TaxiErrorTooltip.constants";
import styles from "./TaxiErrorTooltip.module.css";

interface TaxiErrorTooltipProps extends React.Attributes {
	containerRef?: React.RefObject<HTMLElement>;
	message: string;
}

// This has been adapted from the BUI Tooltip which doesn't allow colour customisation
export const TaxiErrorTooltip: React.FC<TaxiErrorTooltipProps> = (props) => {
	const contentClassName = classNames(tooltipStyles.root, styles["taxi-error-tooltip"]);
	const arrowClassName = classNames(tooltipStyles.arrow, styles["taxi-error-tooltip-arrow"]);

	return (
		<Flyout
			active
			contentClassName={contentClassName}
			contentAttributes={{
				"aria-live": "assertive",
				"data-testid": TEST_IDS.taxiErrorTooltip,
			}}
			position="bottom-start"
			containerRef={props.containerRef}
		>
			<FlyoutContent>
				{props.message}
				<div {...{ "data-testid": TEST_IDS.taxiErrorTooltipArrow }}>
					<FlyoutArrow background="inverted" className={arrowClassName} offset={2} shadow={100} size={7} />
				</div>
			</FlyoutContent>
		</Flyout>
	);
};
