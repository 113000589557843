import React, { useEffect } from "react";
import { Icon } from "@bookingcom/bui-react";
import TransportAirplaneDepartIcon from "@bookingcom/bui-assets-react/streamline/TransportAirplaneDepartIcon";
import { Option } from "@bookingcom/bui-react/components/InputSelect/InputSelect.types";
import { ChangeArgs } from "@bookingcom/bui-react/types/global";
import { TaxiInputSelect } from "../TaxiInputSelect";
import { AirportResponses } from "../../server/services/retrieve-airports/types";
import { setSelectedAirport } from "../../actions/searchForm";
import { useSearchForm } from "../../hooks/useSearchForm";
import { getIataCode, getIataName } from "../../utilities/get-iatas";

interface AirportDropdownProps {
	airports: AirportResponses;
	preferredIata?: string;
}

export const AirportDropdown: React.FC<AirportDropdownProps> = ({ airports, preferredIata }) => {
	const { state, dispatch } = useSearchForm();

	const onChange = (event: ChangeArgs<string, HTMLSelectElement>): void => {
		dispatch(
			setSelectedAirport({
				iata: getIataCode(airports, event.value) as string,
				establishment: event.value,
			}),
		);
	};

	useEffect(() => {
		dispatch(
			setSelectedAirport({
				iata: preferredIata || (getIataCode(airports, airports[0].data?.name as string) as string),
				establishment: preferredIata
					? (getIataName(airports, preferredIata) as string)
					: (airports[0].data?.name as string),
			}),
		);
	}, [airports, dispatch, preferredIata]);

	if (airports.length <= 1) return null;

	const airportTitles: Option[] = airports.map(
		(item) =>
			({
				text: item?.data?.name,
				value: item?.data?.name,
			}) as Option,
	);

	return (
		<TaxiInputSelect
			name="airport-dropdown"
			ariaLabel="Airports"
			options={airportTitles}
			icon={<Icon svg={<TransportAirplaneDepartIcon />} color="neutral" />}
			value={state.selectedAirport?.establishment}
			onChange={onChange}
		/>
	);
};
