import { addDays, addMinutes } from "date-fns";
import { padNumber } from "./generic";
import * as TimesUtil from "./times";

interface DayTranslations {
	dayNames: string[];
}

interface MonthTranslations {
	monthNames: string[];
}

export const getMinDate = (): Date => TimesUtil.getMinValidDateTime();
export const getMaxDate = (): Date => new Date(getMinDate().setMonth(getMinDate().getMonth() + 13));
export const getDefaultDate = (): Date => {
	const currentDate = getMinDate();
	currentDate.setDate(currentDate.getDate() + 2);

	return currentDate;
};

export const formatDate = (date: Date, translations: DayTranslations & MonthTranslations): string => {
	const DD = date.getDate();
	const DDD = translations.dayNames[date.getDay()];
	const MMM = translations.monthNames[date.getMonth()];

	return `${MMM} ${DD}, ${DDD}`;
};

export const formatDateYYYYMMDD = (date: Date): string => {
	const DD = padNumber(date.getDate());
	const MM = padNumber(date.getMonth() + 1);
	const YYYY = date.getFullYear();
	return `${YYYY}-${MM}-${DD}`;
};

export const formatDateYYYYMM = (date: Date): string => {
	const MM = padNumber(date.getMonth() + 1);
	const YYYY = date.getFullYear();
	return `${YYYY}-${MM}`;
};

export const convertMinutesToMultipleOfFive = (minutes: number): number => {
	const minuteAsMultipleOf5 = minutes / 5;

	return Math.ceil(minuteAsMultipleOf5) * 5;
};

export const getValidDropDownDate = (dateToConvert: Date): Date => {
	const dateToConvertMinutes = dateToConvert.getMinutes();
	const minutesAsMultipleOfFive = convertMinutesToMultipleOfFive(dateToConvertMinutes);

	if (minutesAsMultipleOfFive === dateToConvertMinutes) {
		return dateToConvert;
	}

	return addMinutes(dateToConvert, minutesAsMultipleOfFive - dateToConvertMinutes);
};

export const getDateByOffset = (date: Date, offset: number): Date => {
	let baseDate = new Date(date);
	baseDate = addDays(baseDate, offset);
	baseDate.setHours(12, 0, 0, 0);
	return baseDate;
};
