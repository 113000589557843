import { DictionaryObject } from "@bookingcom/lingojs-parser";

export const EMPTY_LEG = {
	pickUp: {
		title: "",
		date: undefined,
		time: undefined,
	},
	dropOff: {
		title: "",
		journeyTime: "",
		date: undefined,
		time: undefined,
	},
};

export type AddReturnSheetProps = {
	lingoLocale: string;
	lingoTranslations: DictionaryObject;
};

export interface AddReturnLeg {
	dropOff: {
		date?: string;
		journeyTime: string | null;
		time?: string;
		title: string;
	};
	pickUp: {
		date?: string;
		time?: string;
		title: string;
	};
}
