import React from "react";
import { BUIProvider } from "@bookingcom/bui-react";
import { getI18NStore } from "@btransport/lingo-client-utils";
import { I18nProvider } from "@bookingcom/lingojs-react";
import { SearchFormProvider } from "../../contexts/SearchFormContext";
import { SearchForm } from "./SearchForm";
import { FORM_TYPES } from "../../constants/events";
import type { TaxiSearchFormProps } from "./types";
import type { SearchType } from "../../types";

import "./TaxiSearchFormVariables.css";

const validateProps = (props: TaxiSearchFormProps): TaxiSearchFormProps => {
	const args = { ...props };
	const isReturnSearch = args.type === undefined || args.type !== FORM_TYPES.SINGLE_SEARCH;

	if (isReturnSearch) {
		args.type = FORM_TYPES.RETURN_SEARCH as SearchType;
	}

	args.isReturn = isReturnSearch ? args.isReturn : false;

	return args;
};

export const TaxiSearchForm: React.FC<TaxiSearchFormProps> = (props) => {
	const i18nStore = getI18NStore(props.lingoLocale, props.lingoTranslations);
	const PROPS = { ...validateProps(props) };

	return (
		<I18nProvider store={i18nStore}>
			<BUIProvider defaultRTL={PROPS.enableRtl} defaultThemeMode={PROPS.defaultTheme}>
				<SearchFormProvider {...PROPS}>
					<SearchForm type={PROPS.type} redirectUrl={PROPS.redirectUrl} />
				</SearchFormProvider>
			</BUIProvider>
		</I18nProvider>
	);
};
