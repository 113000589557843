import React from "react";
import { getImageAssetUrl } from "@bookingcom/bui-assets-react";
import { Button, Stack, Text, Image } from "@bookingcom/bui-react";
import { useTranslations } from "@bookingcom/lingojs-react";

export interface AddReturnErrorProps {
	onCloseEvent: () => void;
}

export const AddReturnError: React.FC<AddReturnErrorProps> = ({ onCloseEvent }) => {
	const { translate: t } = useTranslations();

	return (
		<Stack direction="column" grow alignItems="center">
			<Stack.Item
				grow
				mixin={{
					paddingBlockStart: 6,
					paddingBlockEnd: 2,
				}}
			>
				<Image
					src={getImageAssetUrl({
						setName: "illustrations-traveller",
						assetName: "GroundTransportNoResults",
					})}
					width="168px"
					contentMode="fit"
					fallback={null}
				/>
			</Stack.Item>
			<Stack.Item
				grow
				mixin={{
					paddingBlockEnd: 2,
				}}
			>
				<Text variant="headline_3">{t("rides_web_add_a_return_no_results_modal_title")}</Text>
			</Stack.Item>
			<Stack.Item
				alignSelf="center"
				grow
				mixin={{
					paddingBlockEnd: 4,
				}}
			>
				<Text align="center" variant="body_1">
					{t("rides_web_add_a_return_no_results_modal_subtitle")}
				</Text>
			</Stack.Item>
			<Button
				text={t("android_taxis_flights_number_close")}
				wide
				size="large"
				onClick={onCloseEvent}
				attributes={{
					"data-testid": "error_modal_close_button",
				}}
			/>
		</Stack>
	);
};
