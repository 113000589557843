import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stack, InputSelect, InputText, Icon, Text } from "@bookingcom/bui-react";
import { useTranslations } from "@bookingcom/lingojs-react";
import CalendarIcon from "@bookingcom/bui-assets-react/streamline/CalendarIcon";
import debounce from "lodash/debounce";
import { useSearchForm } from "../../hooks/useSearchForm";
import { generateHours, generateMinutes, getMaxDate, getMinDate } from "../TaxiDateTimePicker/dateTimeUtil";
import { SET_DATE_TIME } from "../../actions/searchForm";
import { SearchFormStateInterface } from "../../contexts/SearchFormContext/types.js";
import { TaxiDateTimePickerProps } from "../TaxiDateTimePicker/TaxiDateTimePicker";
import { SEARCH_TYPES } from "../../constants/events";
import "./TaxiDateTimeKeyboardPicker.css";

type TaxiDateTimeKeyboardPickerProps = {
	name: TaxiDateTimePickerProps["name"];
	onFocusChange: (state: boolean) => void;
};

export const INPUT_VALUES = {
	DAY: {
		MIN: 1,
		MAX: 31,
	},
	MONTH: {
		MIN: 1,
		MAX: 12,
	},
	YEAR: {
		MIN: getMinDate().getFullYear(),
		MAX: getMaxDate().getFullYear(),
	},
};

type KeyboardController<DateType = string, TimeType = string> = {
	dayOfMonth: DateType;
	hour: TimeType;
	minute: TimeType;
	month: DateType;
	year: DateType;
};

function createKeyboardControllerFromState(
	state: SearchFormStateInterface,
	type: TaxiDateTimeKeyboardPickerProps["name"],
): KeyboardController {
	if (type === SEARCH_TYPES.RETURN) {
		return {
			dayOfMonth: state.returnDateTime.getDate().toString(),
			hour: state.returnDateTime.getHours().toString(),
			minute: state.returnDateTime.getMinutes().toString(),
			month: (state.returnDateTime.getMonth() + 1).toString(),
			year: state.returnDateTime.getFullYear().toString(),
		};
	}

	return {
		dayOfMonth: state.outboundDateTime.getDate().toString(),
		hour: state.outboundDateTime.getHours().toString(),
		minute: state.outboundDateTime.getMinutes().toString(),
		month: (state.outboundDateTime.getMonth() + 1).toString(),
		year: state.outboundDateTime.getFullYear().toString(),
	};
}

function generateDate(controller: KeyboardController): Date {
	const YEAR = controller.year;
	const MONTH = controller.month.padStart(2, "0");
	const DAY = controller.dayOfMonth.padStart(2, "0");
	const HOUR = controller.hour.padStart(2, "0");
	const MINUTE = controller.minute.padStart(2, "0");

	return new Date(`${YEAR}-${MONTH}-${DAY}T${HOUR}:${MINUTE}`);
}

export const TaxiDateTimeKeyboardPicker: React.FC<TaxiDateTimeKeyboardPickerProps> = (props) => {
	const { name, onFocusChange } = props;
	const { state, dispatch } = useSearchForm();

	const dayOfTheMonthInput = useRef<HTMLInputElement>();
	const monthInput = useRef<HTMLInputElement>();
	const yearInput = useRef<HTMLInputElement>();
	const hourDropdown = useRef<HTMLSelectElement>();
	const minuteDropdown = useRef<HTMLSelectElement>();
	const inputContainer = useRef<HTMLDivElement>();

	const [showInputs, setShowInputs] = useState(false);
	const [keyboardController, setKeyboardController] = useState<KeyboardController>(
		createKeyboardControllerFromState(state, name),
	);

	const { translate: t } = useTranslations();

	function getValidDate(controller: KeyboardController): Date {
		const clonedController: KeyboardController<number> = Object.assign(controller, {
			dayOfMonth: parseInt(controller.dayOfMonth, 10),
			year: parseInt(controller.year, 10),
			month: parseInt(controller.month, 10),
		});

		/* eslint-disable no-restricted-globals */
		if (clonedController.year > INPUT_VALUES.YEAR.MAX) {
			clonedController.year = INPUT_VALUES.YEAR.MAX;
		}

		if (!clonedController.year || isNaN(clonedController.year) || clonedController.year < INPUT_VALUES.YEAR.MIN) {
			clonedController.year = INPUT_VALUES.YEAR.MIN;
		}

		if (clonedController.month > INPUT_VALUES.MONTH.MAX) {
			clonedController.month = INPUT_VALUES.MONTH.MAX;
		}

		if (!clonedController.month || isNaN(clonedController.month) || clonedController.month < INPUT_VALUES.MONTH.MIN) {
			clonedController.month = INPUT_VALUES.MONTH.MIN;
		}

		if (clonedController.dayOfMonth > INPUT_VALUES.DAY.MAX) {
			clonedController.dayOfMonth = INPUT_VALUES.DAY.MAX;
		}

		if (
			!clonedController.dayOfMonth ||
			isNaN(clonedController.dayOfMonth) ||
			clonedController.dayOfMonth < INPUT_VALUES.DAY.MIN
		) {
			clonedController.dayOfMonth = INPUT_VALUES.DAY.MIN;
		}
		/* eslint-enable */

		const updatedController: KeyboardController = Object.assign(clonedController, {
			dayOfMonth: clonedController.dayOfMonth.toString(),
			year: clonedController.year.toString(),
			month: clonedController.month.toString(),
		});

		setKeyboardController(updatedController);

		return generateDate(updatedController);
	}

	const debounceChangeFocusState = useCallback(
		(inputState: boolean) => {
			const changeFocusState = (newState: boolean): void => {
				setShowInputs(newState);
				onFocusChange(newState);
			};

			return debounce(changeFocusState, 100)(inputState);
		},
		[onFocusChange],
	);

	const onBlur = (): void => {
		dispatch({
			type: SET_DATE_TIME,
			payload: {
				name,
				date: getValidDate(keyboardController),
			},
		});
	};

	const onDayOfTheMonthChange = (): void => {
		if (!dayOfTheMonthInput.current) {
			return;
		}

		setKeyboardController({
			...keyboardController,
			dayOfMonth: dayOfTheMonthInput.current.value,
		});
	};

	const onMonthChange = (): void => {
		if (!monthInput.current) {
			return;
		}

		setKeyboardController({
			...keyboardController,
			month: monthInput.current.value,
		});
	};

	const onYearChange = (): void => {
		if (!yearInput.current) {
			return;
		}

		setKeyboardController({
			...keyboardController,
			year: yearInput.current.value,
		});
	};

	const onHourChange = (): void => {
		if (!hourDropdown.current) {
			return;
		}

		setKeyboardController({
			...keyboardController,
			hour: hourDropdown.current.value,
		});
	};

	const onMinuteChange = (): void => {
		if (!minuteDropdown.current) {
			return;
		}

		setKeyboardController({
			...keyboardController,
			minute: minuteDropdown.current.value,
		});
	};

	const containerClasses = (): string => {
		const classes = ["taxi-date-time-keyboard-picker"];

		if (showInputs) {
			classes.push("taxi-date-time-keyboard-picker--focused");
		}

		return classes.join(" ");
	};

	useEffect(() => {
		setKeyboardController(createKeyboardControllerFromState(state, name));
	}, [name, state]);

	useEffect(() => {
		const currentRef = inputContainer.current;

		const focusIn = (): void => {
			debounceChangeFocusState(true);
		};

		const focusOut = (): void => {
			debounceChangeFocusState(false);
		};

		currentRef?.addEventListener("focusin", focusIn);
		currentRef?.addEventListener("focusout", focusOut);

		return () => {
			currentRef?.removeEventListener("focusin", focusIn);
			currentRef?.removeEventListener("focusout", focusOut);
		};
	}, [debounceChangeFocusState]);

	return (
		<Stack
			className={containerClasses()}
			direction="row"
			attributes={{
				"ref": inputContainer,
				"data-test": `taxi-date-date-time-keyboard-picker-${name}`,
			}}
			gap={1}
			justifyContent="space-between"
			alignItems="center"
		>
			<Stack.Item>
				<Icon svg={<CalendarIcon />} />
			</Stack.Item>
			<Stack.Item>
				<InputText
					value={keyboardController.dayOfMonth}
					name={`${name}__input--day`}
					className="taxi-autocomplete-search-box__container taxi-date-time-keyboard-picker__input input-container--no-style"
					onBlur={onBlur}
					onChange={onDayOfTheMonthChange}
					inputAttributes={{
						"aria-label": t(
							`gt_mig_rides_web_search_form_search_keyboard-date-${name === "oneway" ? "pickup" : "dropoff"}-day`,
						),
						"data-test": `${name}__input--day`,
						"max": INPUT_VALUES.DAY.MAX,
						"min": INPUT_VALUES.DAY.MIN,
						"ref": dayOfTheMonthInput,
						"type": "number",
					}}
				/>
			</Stack.Item>
			<Stack.Item>
				<Text
					attributes={{
						"aria-hidden": true,
					}}
					variant="body_2"
				>
					/
				</Text>
			</Stack.Item>
			<Stack.Item>
				<InputText
					value={keyboardController.month}
					name={`${name}__input--month`}
					className="taxi-autocomplete-search-box__container taxi-date-time-keyboard-picker__input input-container--no-style"
					onBlur={onBlur}
					onChange={onMonthChange}
					inputAttributes={{
						"aria-label": t(
							`gt_mig_rides_web_search_form_search_keyboard-date-${name === "oneway" ? "pickup" : "dropoff"}-month`,
						),
						"data-test": `${name}__input--month`,
						"max": INPUT_VALUES.MONTH.MAX,
						"min": INPUT_VALUES.MONTH.MIN,
						"ref": monthInput,
						"type": "number",
					}}
				/>
			</Stack.Item>
			<Stack.Item>
				<Text
					attributes={{
						"aria-hidden": true,
					}}
					variant="body_2"
				>
					/
				</Text>
			</Stack.Item>
			<Stack.Item>
				<InputText
					value={keyboardController.year}
					name={`${name}__input--year`}
					className="taxi-autocomplete-search-box__container taxi-date-time-keyboard-picker__year-input input-container--no-style"
					onBlur={onBlur}
					onChange={onYearChange}
					inputAttributes={{
						"aria-label": t(
							`gt_mig_rides_web_search_form_search_keyboard-date-${name === "oneway" ? "pickup" : "dropoff"}-year`,
						),
						"data-test": `${name}__input--year`,
						"max": INPUT_VALUES.YEAR.MAX,
						"min": INPUT_VALUES.YEAR.MIN,
						"ref": yearInput,
						"type": "number",
					}}
				/>
			</Stack.Item>
			<Stack.Item>
				<Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
					<Stack.Item>
						<InputSelect
							value={keyboardController.hour}
							name={`${name}__input--hour`}
							options={generateHours()}
							onBlur={onBlur}
							onChange={onHourChange}
							className="taxi-autocomplete-search-box__container select-container--no-style"
							inputClassName="taxi-autocomplete-search-box__container-select"
							inputAttributes={{
								"data-test": `${name}__input--hour`,
								"aria-label": t(
									`gt_mig_rides_web_search_form_search_keyboard-date-${name === "oneway" ? "pickup" : "dropoff"}-hour`,
								),
								"ref": hourDropdown,
							}}
						/>
					</Stack.Item>
					<Stack.Item>
						<Text
							attributes={{
								"aria-hidden": true,
							}}
							variant="body_2"
						>
							:
						</Text>
					</Stack.Item>
					<Stack.Item>
						<InputSelect
							value={keyboardController.minute}
							name={`${name}__input--minute`}
							options={generateMinutes()}
							onBlur={onBlur}
							onChange={onMinuteChange}
							className="taxi-autocomplete-search-box__container select-container--no-style"
							inputClassName="taxi-autocomplete-search-box__container-select"
							inputAttributes={{
								"data-test": `${name}__input--minute`,
								"aria-label": t(
									`gt_mig_rides_web_search_form_search_keyboard-date-${name === "oneway" ? "pickup" : "dropoff"}-minute`,
								),
								"ref": minuteDropdown,
							}}
						/>
					</Stack.Item>
				</Stack>
			</Stack.Item>
		</Stack>
	);
};
