import { RidesPubSub } from "@btransport/rides-pubsub-library";
import {
	SUBMIT_FORM,
	SET_PASSENGERS,
	SET_RESULTS,
	CLEAR_RESULTS,
	SELECT_RESULT,
	SCREEN_SIZE,
	SET_RETURN,
	SWAP_LOCATIONS,
	TOGGLE_ERROR,
	TOGGLE_ROUTE_PLANNER,
	SET_DATE_TIME,
	SET_SELECTED_AIRPORT,
	SET_RETURN_BANNER_CLICKED,
} from "../actions/searchForm";
import type { ActionInterface, SearchFormStateInterface } from "../contexts/SearchFormContext/types";
import { submitFormAction } from "../actions/submit-form-action";
import { SEARCH_FORM_HAS_HEADER_EVENT } from "../constants/events";

export const searchFormReducer = (
	state: SearchFormStateInterface,
	action: ActionInterface,
): SearchFormStateInterface => {
	switch (action.type) {
		case SUBMIT_FORM: {
			return submitFormAction(state, action);
		}

		case SET_PASSENGERS: {
			return {
				...state,
				passengers: (action.payload as string) || state.passengers,
			};
		}

		case SET_SELECTED_AIRPORT: {
			return {
				...state,
				selectedAirport: (action.payload as SearchFormStateInterface["selectedAirport"]) || state.selectedAirport,
			};
		}

		case SELECT_RESULT: {
			const { name, result } = action.payload as { name: string; result: boolean };

			return {
				...state,
				[name]: {
					...state[name as "pickup" | "dropoff"],
					selected: result,
				},
			};
		}

		case SET_RESULTS: {
			const { name, response } = action.payload as { name: string; response: { results: unknown } };

			return {
				...state,
				[`${name}`]: {
					...state[name as "pickup" | "dropoff"],
					results: response.results,
				},
			};
		}

		case CLEAR_RESULTS: {
			const { name } = action.payload as { name: string };

			return {
				...state,
				[name as "pickup" | "dropoff"]: {
					results: [],
					hasError: false,
				},
			};
		}

		case SCREEN_SIZE: {
			return {
				...state,
				screenSize: action.payload as SearchFormStateInterface["screenSize"],
			};
		}

		case SET_RETURN: {
			const { isReturn } = action.payload as { isReturn: boolean };

			return {
				...state,
				isReturn,
			};
		}

		case SET_RETURN_BANNER_CLICKED: {
			const { returnBannerClicked } = action.payload as { returnBannerClicked: boolean };

			return {
				...state,
				returnBannerClicked,
			};
		}

		case SWAP_LOCATIONS: {
			return {
				...state,
				pickup: state.dropoff,
				dropoff: state.pickup,
			};
		}

		case TOGGLE_ERROR: {
			const { name, hasError } = action.payload as { hasError: boolean; name: string };

			if (name !== "pickup" && name !== "dropoff") {
				return {
					...state,
					[name]: hasError,
				};
			}

			return {
				...state,
				[name]: {
					...state[name as "pickup" | "dropoff"],
					hasError,
				},
			};
		}

		case TOGGLE_ROUTE_PLANNER: {
			const { name } = action.payload as { name: string };

			if (state.usePubSubEvent) {
				RidesPubSub.publish(SEARCH_FORM_HAS_HEADER_EVENT, !state.showRoutePlanner);
			}

			return {
				...state,
				showRoutePlanner: !state.showRoutePlanner,
				elementToFocusOn: name,
			};
		}

		case SET_DATE_TIME: {
			const { name, date } = action.payload as { date: Date; name: string };

			return {
				...state,
				...(name === "oneway" && {
					outboundDateTime: date,
				}),
				...(name === "return" && {
					returnDateTime: date,
				}),
			};
		}

		default: {
			return state;
		}
	}
};
