import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslations } from "@bookingcom/lingojs-react";
import type { PressHandler } from "@bookingcom/bui-react/components/Actionable/Actionable.types";
import Button from "@bookingcom/bui-react/components/Button";
import { trackEvent } from "@btransport/rides-tracking-publisher";
import { TaxiRoutePlanner } from "../TaxiRoutePlanner";
import { TaxiLocationManager } from "../TaxiLocationManager";
import { TaxiDateTimePickerFormElement } from "../TaxiDateTimePickerFormElement";
import { TaxiPassengers } from "../TaxiPassengers";
import { ReturnToggle } from "../ReturnToggle";
import { TaxiErrorContainer } from "../TaxiErrorContainer";
import { useSearchForm } from "../../hooks/useSearchForm";
import { setScreenSize, submitForm, toggleRoutePlanner, setSelectedAirport } from "../../actions/searchForm";
import { useContainerResize } from "../../hooks/useContainerResize";
import { AirportDropdown } from "../AirportDropdown";
import { TEST_IDS } from "./SearchForm.constants";
import styles from "./TaxiSearchForm.module.css";
import { SearchType } from "../../types";
import { AddReturnSheet } from "../AddReturnSheet";

interface SearchFormProps extends React.Attributes {
	redirectUrl: string;
	type?: SearchType;
}

const getSearchFormTypeModifier = (type: SearchType): string =>
	type === undefined || type === "RETURN_SEARCH"
		? "taxi-search-form--return-search-type"
		: "taxi-search-form--single-search-type";

export const SearchForm: React.FC<SearchFormProps> = (props) => {
	const searchFormContainer = useRef<HTMLDivElement>(null);
	const { state, dispatch } = useSearchForm();
	const { isLarge, isMedium, isSmall } = useContainerResize(searchFormContainer);
	const [isLoading, setIsLoading] = useState(true);
	const {
		airports,
		dateRangeEnd,
		dateRangeStart,
		dropoff,
		discount,
		hidePassengerField,
		isReturn,
		maxPassengers,
		pickup,
		preferredIata,
		enableLandingPageVariant,
		lang,
	} = state;

	const { translate: t } = useTranslations();

	const isFreeTaxi = discount === "FREE_TAXI";
	const isMultiIATA = !!(airports?.length > 1);
	const isReturnSearchType = props.type === undefined || props.type !== "SINGLE_SEARCH";

	const handleSubmit = (e: React.MouseEvent): void => {
		e.preventDefault();

		trackEvent({
			category: "SearchComponent",
			action: "Click",
			label: `Search ${isReturn ? "Return" : "Single"}`,
			customDimensions: window.GACustomDimensions || [],
		});

		dispatch(submitForm(props.redirectUrl));
	};

	const onClose = (): void => {
		dispatch(toggleRoutePlanner());
	};

	useEffect(() => {
		if (!isMultiIATA && airports) {
			dispatch(
				setSelectedAirport({
					iata: airports[0]?.data?.iataCode as string,
					establishment: airports[0]?.data?.name as string,
				}),
			);
		}
	}, [airports, dispatch, isMultiIATA]);

	useEffect(() => {
		dispatch(
			setScreenSize({
				isLarge,
				isMedium,
				isSmall,
			}),
		);

		setIsLoading(false);

		if (isLarge && state.showRoutePlanner) {
			dispatch(toggleRoutePlanner());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, isLarge, isMedium, isSmall]);

	useEffect(() => {
		if (state.showRoutePlanner && pickup.selected && dropoff.selected) {
			dispatch(toggleRoutePlanner());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pickup.selected, dropoff.selected, dispatch]);

	useEffect(() => {
		const body = document.querySelector("body");
		const style = body?.getAttribute("style") || "";

		if (state.showRoutePlanner) {
			body?.setAttribute("style", `overflow-y: hidden;${style}`);
			return;
		}

		body?.setAttribute("style", style.replace("overflow-y: hidden;", ""));
	}, [state.showRoutePlanner]);

	const getSearchFormClasses = (): string => {
		const searchFormClasses = [styles["taxi-search-form"]];
		const searchFormTypeModifier = getSearchFormTypeModifier(props.type);
		const searchFormType = styles[`${searchFormTypeModifier}${hidePassengerField ? "--hidden-passenger" : ""}`];
		searchFormClasses.push(searchFormType);

		if (isLoading) {
			searchFormClasses.push(styles["taxi-search-form--hidden"]);
		}

		if (isMedium) {
			searchFormClasses.push(styles["taxi-search-form--medium-size"]);
		}

		if (isSmall) {
			searchFormClasses.push(styles["taxi-search-form--small-size"]);
		}

		return searchFormClasses.join(" ");
	};

	const dateTimePickerClassName = useMemo(
		() =>
			[
				styles[`taxi-search-form__container-item`],
				styles[`taxi-search-form__container-date-time${hidePassengerField ? "--full-width" : ""}`],
			].join(" "),
		[hidePassengerField],
	);

	const searchFormClasses = useMemo(
		() =>
			[
				discount === "FREE_TAXI"
					? airports?.length > 1
						? styles["taxi-search-form__container_free_taxi_multi_iata"]
						: styles["taxi-search-form__container_free_taxi"]
					: styles["taxi-search-form__container"],
				hidePassengerField ? styles["taxi-search-form__container--hidden-passenger"] : "",
			]
				.join(" ")
				.trim(),
		[airports?.length, discount, hidePassengerField],
	);

	const showSearchForm = (): JSX.Element => (
		<form className={getSearchFormClasses()}>
			{isReturnSearchType && discount !== "FREE_TAXI" && <ReturnToggle isLandingPage={enableLandingPageVariant} />}
			<div
				data-testid={TEST_IDS.searchFormContainer}
				// eslint-disable-next-line quotes
				className={searchFormClasses}
			>
				{!isFreeTaxi && (
					<div className={styles["taxi-search-form__container-item"]}>
						{!isLarge && <TaxiErrorContainer />}
						<TaxiLocationManager />
					</div>
				)}
				{isFreeTaxi && isMultiIATA && airports && (
					<div className={styles["taxi-search-form__container-item"]}>
						<AirportDropdown airports={airports} preferredIata={preferredIata} />
					</div>
				)}
				<div className={dateTimePickerClassName}>
					<TaxiDateTimePickerFormElement name="oneway" dateRangeStart={dateRangeStart} dateRangeEnd={dateRangeEnd} />
				</div>
				{isReturnSearchType && !isFreeTaxi && (
					<div className={dateTimePickerClassName}>
						<TaxiDateTimePickerFormElement name="return" />
					</div>
				)}
				{isReturnSearchType && (
					<AddReturnSheet dateRangeStart={dateRangeStart} dateRangeEnd={dateRangeEnd} lang={lang} />
				)}
				{!hidePassengerField && (
					<div
						className={[
							styles["taxi-search-form__container-item"],
							styles["taxi-search-form__container-taxi-passengers"],
						].join(" ")}
					>
						<TaxiPassengers maxPassengers={maxPassengers} />
					</div>
				)}
				<div
					className={[
						styles["taxi-search-form__container-item"],
						styles["taxi-search-form__container-submit-button"],
					].join(" ")}
				>
					<Button
						text={
							discount === "FREE_TAXI"
								? t("gt_mig_rides_search-form_update-button")
								: t("gt_mig_rides_web_search_form_search_search")
						}
						type="submit"
						size="large"
						attributes={{
							"data-testid": TEST_IDS.searchFormSubmitButton,
						}}
						className={styles["taxi-search-form__container-item__button"]}
						onClick={handleSubmit as PressHandler}
						wide
					/>
				</div>
			</div>
		</form>
	);

	const showRoutePlanner = (): JSX.Element => (
		<div className={getSearchFormClasses()}>
			<TaxiRoutePlanner
				onClose={onClose}
				title={t("gt_mig_rides_web_search_form_route-panner_header")}
				closeButtonAriaLabel={t("gt_mig_rides_web_search_form_search_close-route-planner")}
			/>
		</div>
	);

	// TODO: Refactor into JSX elements
	return <div ref={searchFormContainer}>{state.showRoutePlanner ? showRoutePlanner() : showSearchForm()}</div>;
};
